@media screen and (max-width: 800px) {
    .hidden{
        display: none !important;
      }
}

@media screen and (max-width: 600px) {
    .row-to-col {
      flex-direction: column !important;
    }

    .mat-mdc-radio-button {
      margin-right: 2px !important;
    }

    .w-1\/2{
        width: 100% !important;
    }

    .w-2\/3{
        width: 100% !important;
    }
}