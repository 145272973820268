// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@use './assets/css/responsive.css';

@use '../node_modules/angular-calendar/css/angular-calendar.css';

/* You can add global styles to this file, and also import other style files */
@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

@font-face {
  font-family: 'Lexend';
  src: url('./assets/Lexend-VariableFont_wght.ttf') format('truetype');
  font-weight: 300 400 700 500 600;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('./../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src:
    local('Material Icons'),
    local('MaterialIcons-Regular'),
    url('./../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.woff2') format('woff2'),
    url('./../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.woff') format('woff'),
    url('/../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

$md-medinbox1: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-medinbox2: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-medinbox3: (
  50: #e0edfb,
  100: #b3d1f5,
  200: #80b3ef,
  300: #4d95e8,
  400: #267ee3,
  500: #0067de,
  600: #005fda,
  700: #0054d5,
  800: #004ad1,
  900: #0039c8,
  A100: #f1f4ff,
  A200: #becbff,
  A400: #8ba3ff,
  A700: #718fff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Medinbox-Link-Front-primary: mat.m2-define-palette($md-medinbox1);
$Medinbox-Link-Front-accent: mat.m2-define-palette($md-medinbox2);
$Medinbox-Link-Front-rainbow: mat.m2-define-palette($md-medinbox3);

// The warn palette is optional (defaults to red).
$Medinbox-Link-Front-warn: mat.m2-define-palette(mat.$m2-red-palette);
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Lexend',
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Medinbox-Link-Front-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $Medinbox-Link-Front-primary,
      accent: $Medinbox-Link-Front-rainbow,
      warn: $Medinbox-Link-Front-warn,
      tertiary: $Medinbox-Link-Front-rainbow,
    ),
    typography: $custom-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Medinbox-Link-Front-theme);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// (v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
// @include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

:root {
  --font-size-l: 16px;
  --font-size-m: 14px;
  --font-size-xl: 18px;

  --toolbar-size: 80px;

  --font-family-lexend: 'Lexend', sans-serif;

  --cape-cod: #414141;
  --celeste: #cccccc;
  --dove-gray: #707070;
  --navy-blue: #1d70e1;
  --sonic-silver: #777777;
  --white: #ffffff;
  --black: #000000;

  --text-color: #ffffff;
  --sub-text-color: #ebebeb;
  --third-text-color: #cfcfcf;
  --text-color-blue: #2967de;
  --bg-perm-icon: #d8d8d8;
  --bg-toolbar: #1f1f1f;
  --bg-toolbar-icon: #3f4245;
  --bg-drawing-icon: #303335;
  --bg-modal: #191919;
  --bg-default-icon: #3e3e3e;
  --bg-button: #0067de;
  --bg-button-disable: #c6c6c6;
  --bg-drag: #0068de52;
  --drag-icon-color: #ffffff33;

  --bg-side-link: #676767;
  --bg-side-menu: #ffffff1a;
  --bg-chat-text-area: #4a4a4a;
  --bg-stop-icon: #a50000;
  --bg-notification-decline: #5c5c5c;
  --bg-screen: #f8fbff;
  --input-palceholder: #727272;

  --title-text-color: #102753;
  --border-input-color: #8b8b8b;
  --profile-picture-color: #50ca6c;
  --heaset-on-color: #4fb92f;
  --placeholder-color: #888888;
  --border-color: #363636;
  --speaking-color: #0c600c;
  --section-color: #292929;
  --participant-text: #797979;

  --dialog-title: #002871;

  --svg-enabled-icon: #2967de;
  --svg-disabled-icon: #a8a8a8;

  --disabled-text-color: #a5a5a5;
}

.cdk-global-scrollblock {
  overflow-y: hidden;
}

// Custom basic css
html,
body {
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.mat-typography {
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  font-family: var(--font-family-lexend) !important;
  letter-spacing: inherit !important;
}

body {
  margin: 0;
  font-family: var(--font-family-lexend);
  background-color: var(--bg-screen);
}

.card {
  flex-direction: column;
  align-items: center;
  width: 500px;
}

.glassmorph {
  -webkit-backdrop-filter: blur(20px) brightness(130%);
  backdrop-filter: blur(20px) brightness(130%);
  background-color: var(--white-2);
  border-radius: 15px;
  max-height: 95%;
  max-width: 95%;
  align-self: center;
  align-content: center;
  min-height: 10px;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 0.5rem;
}

@supports not ((-webkit-backdrop-filter: blur()) or (backdrop-filter: blur())) {
  .glassmorph {
    background: rgba(255, 255, 255, 0.8);
  }
}

.glassmorph-white {
  background-color: var(--white);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

input {
  border: 0.5px solid var(--border-input-color);
  box-shadow: none !important;
  border-radius: 5px;
  background-color: var(--white) !important;
  padding-left: 0.5rem;
  height: 40px;
  margin: 0 !important;
  font-size: initial;
}

.hidden {
  display: none;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.button {
  background-color: var(--navy-blue) !important;
  color: var(--text-color) !important;
  border-radius: 5px !important;
  height: 45px !important;
}

::ng-deep .mdc-button {
  font-family: var(--font-family-lexend);
  font-size: inherit !important;
  font-weight: inherit !important;
}

.secondary-button {
  border-radius: 5px !important;
  height: 45px !important;
  border: 1px solid var(--text-color-blue) !important;
}

.flex-row {
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
}

.title {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: 18px;
  min-height: 25px;
}

.mdc-dialog__title {
  color: inherit !important;
}

.subtitle {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: 16px;
  min-height: 22px;
}

.border-1px-celeste {
  border: 1px solid var(--celeste);
}

.border-1px-white {
  border: 1px solid var(--white);
}

.border-1px-grey {
  border: 1px solid var(--bg-side-link);
}

.border-3px-blue {
  border: 3px solid var(--bg-button);
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: inherit !important;
  box-shadow: inherit !important;
}

.medinbox-snackbar {
  z-index: 999;

  .mdc-snackbar__surface {
    background-color: var(--bg-toolbar) !important;
    box-shadow: inherit !important;

    .mdc-button__label {
      font-family: 'Material Icons', sans-serif;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
    }

    .mat-mdc-standard-chip .mdc-evolution-chip__text-label {
      color: inherit !important;
    }
  }

  &.error {
    .mat-mdc-snack-bar-action {
      color: #d50000 !important;
    }
  }

  &.warning {
    .mat-mdc-snack-bar-action {
      color: yellow !important;
    }
  }

  &.success {
    .mat-mdc-snack-bar-action {
      color: green !important;
    }
  }
}

/**
 *
 * FIX ANGULAR MATERIAL MDC MIGRATION
 *
 */

.white-modal {
  .mat-mdc-dialog-container {
    background: var(--white) !important;
    border-radius: 10px;
    color: var(--dialog-title);
  }
}

.custom-modal {
  .mat-mdc-dialog-container {
    background: var(--bg-modal) !important;
    border-radius: 10px;
    color: var(--sub-text-color);
  }
}

.event-modal {
  .mat-mdc-dialog-container {
    background: var(--white) !important;
    border-radius: 10px;
    color: var(--dialog-title);
    padding: 0;
  }
}

.disclaimer-modal {
  .mat-mdc-dialog-container {
    background: var(--bg-screen) 0% 0% no-repeat padding-box !important;
    border-radius: 10px !important;
    opacity: 1;
    padding: 2rem 3rem;
  }
}

.disclaimer-modal-full-height {
  max-height: 90vh;
}

.mat-mdc-dialog-container {
  background: var(--bg-modal) !important;
  border-radius: 10px !important;
  color: var(--sub-text-color);
  padding: 18px 25px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background: initial !important;
  overflow-y: inherit !important;
  box-shadow: inherit !important;
}

.mat-mdc-dialog-actions {
  justify-content: center !important;
}

.mat-mdc-menu-item {
  min-height: inherit !important;
}

.mdc-list-item__primary-text {
  color: inherit !important;
}

.mat-icon svg {
  max-height: inherit !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__action--primary:before {
  border-radius: 5px !important;
}

.mat-mdc-raised-button {
  width: fit-content;
  box-shadow: none !important;
  padding: 0px 30px !important;
  min-width: 80px !important;
  min-height: 50px !important;
}

.button-cancel {
  background-color: transparent !important;
  border: 1px solid var(--text-color-blue) !important;
  font-family: var(--font-family-lexend);
  font-size: 15px;
  line-height: 19px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  letter-spacing: 0px !important;
  color: var(--text-color-blue) !important;
}

.button-ok {
  background: var(--bg-button) 0% 0% no-repeat padding-box !important;
  font-family: var(--font-family-lexend);
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  letter-spacing: 0px !important;
  color: var(--text-color) !important;
}

.button-cases-ok {
  background: var(--bg-button) 0% 0% no-repeat padding-box !important;
  color: var(--text-color) !important;
  min-height: 0px !important;
  min-width: 0px !important;
  height: 43px;
  width: 142px;
  text-align: center;
  font: normal normal normal 15px/22px var(--font-family-lexend);
  letter-spacing: 0px !important;
  opacity: 1;
  font-weight: normal !important;
}

.button-cases-cancel {
  background-color: transparent !important;
  border: 1px solid var(--text-color-blue) !important;
  color: var(--text-color-blue) !important;
  min-height: 0px !important;
  min-width: 0px !important;
  height: 43px;
  width: 142px;
  text-align: center;
  font: normal normal normal 15px/22px var(--font-family-lexend);
  letter-spacing: 0px !important;
  opacity: 1;
}

.button-remove {
  background: #d50000 0% 0% no-repeat padding-box !important;
  font-family: var(--font-family-lexend);
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  letter-spacing: 0px !important;
  color: var(--text-color) !important;
}

.mdc-evolution-chip__action::before {
  border: none !important;
}

.mat-mdc-raised-button:disabled {
  background-color: var(--bg-button-disable) !important;
}

.mat-mdc-raised-button:not(:disabled) {
  background-color: inherit;
}

.mat-mdc-chip-option {
  height: auto !important;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--dove-gray);
}

/**
 * END FIX
 */

/**
 *
 * CUSTOM MAT SLIDE TOGGLE
 *
 */
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background-color: var(--text-color-blue) !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background-color: var(--sub-text-color) !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: var(--sub-text-color) !important;
}

.mdc-switch__track {
  height: 22px !important;
  width: 36.5px !important;
  border-radius: 14px !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background: var(--bg-notification-decline) !important;
}

.mat-mdc-slide-toggle-thumb-container {
  height: 18px !important;
  width: 18px !important;
  top: 2px !important;
  left: 1px !important;
}

.mdc-switch__handle {
  height: 18px !important;
  width: 18px !important;
  background-color: var(--bg-toolbar) !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__shadow {
  background-color: var(--bg-toolbar) !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  fill: var(--bg-toolbar) !important;
}

/**
 * END CUSTOM
 */

.manage-screen-button {
  .mat-icon {
    display: flex !important;
    width: inherit !important;
  }
}

.mdc-icon-button {
  padding: inherit !important;
}

.cdk-overlay-container {
  z-index: 999 !important;

  .mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step {
    border-radius: 10px;
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step.arrow.mat-menu-above:not(.horizontal):after {
    border-top-color: var(--bg-modal);
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step.arrow.mat-menu-above:not(.horizontal):before {
    border-top-color: var(--bg-modal);
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step.arrow.mat-menu-below:not(.horizontal):before {
    border-bottom-color: var(--bg-modal);
  }
  .mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step.arrow.mat-menu-below:not(.horizontal):after {
    border-bottom-color: var(--bg-modal);
  }

  mat-card {
    border: var(--bg-modal) !important;
    background-color: var(--bg-modal) !important;
    color: var(--white) !important;
    font: normal normal 300 13px/16px var(--font-family-lexend);

    mat-card-title {
      color: var(--text-color-blue);
      font: normal normal normal 18px/23px var(--font-family-lexend);
    }

    .mat-mdc-card-header {
      mat-icon {
        color: var(--white) !important;
      }
    }

    .mdc-button__label {
      color: var(--white);
    }

    mat-card-actions {
      font: normal normal normal 14px/18px var(--font-family-lexend);
      display: flex;
      justify-content: space-between;
      .progress {
        color: var(--white) !important ;
      }
      button {
        background-color: var(--text-color-blue);
      }

      button.prev {
        background-color: var(--bg-modal) !important;
        width: 100px;
        font: normal normal normal 15px/22px var(--font-family-lexend);
        color: var(--text-color-blue);
        justify-content: flex-start;
        .mdc-button__label {
          color: var(--text-color-blue);
        }
      }
      .mat-mdc-button:disabled {
        mat-icon {
          color: var(--bg-modal) !important;
        }
      }

      mat-icon {
        color: var(--text-color-blue);
      }

      button.next {
        mat-icon {
          color: var(--white);
        }
      }
    }
  }
}

.cdk-overlay-pane {
  pointer-events: auto !important;
}

.mat-mdc-tooltip-panel-non-interactive {
  pointer-events: none !important;
}

.logo {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(335deg) brightness(102%) contrast(102%) drop-shadow(5px 5px 10px var(--black));
}

.icon-white {
  fill: var(--white) !important;
  stroke: var(--white) !important;
}

.icon-white-alt {
  fill: none !important;
  stroke: var(--white) !important;
}

.icon-blue {
  fill: var(--bg-button) !important;
  stroke: var(--bg-button) !important;
}

.icon-blue-alt {
  fill: none !important;
  stroke: var(--bg-button) !important;
}

.mat-button-disabled {
  background-color: var(--bg-button-disable) !important;

  .mat-button-wrapper {
    color: var(--white) !important;
  }
}

.back {
  color: var(--text-color-blue);
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0;
}

.medinbox-logo {
  max-width: 306px;
  margin: 1rem;
  cursor: pointer;
}

.criteria {
  font-family: var(--font-family-lexend);
  font-size: 13px;
  line-height: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  color: var(--bg-notification-decline);
  letter-spacing: 0;
}

.input-error {
  border-radius: 5px;
  border: 2px solid var(--bg-stop-icon);
}

.error-input {
  width: auto;
  align-self: center;
  position: absolute;
  right: 1rem;
  color: var(--bg-stop-icon);
  pointer-events: none;
}

.error-input-outside-box {
  text-align: end;
  width: auto;
  align-self: center;
  right: 1rem;
  color: var(--bg-stop-icon);
  pointer-events: none;
  font-size: 14px;
  margin-bottom: -12px;
  margin-right: 1rem;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.error-input-outside-box-margin {
  text-align: end;
  width: auto;
  align-self: center;
  right: 1rem;
  color: var(--bg-stop-icon);
  pointer-events: none;
  font-size: 14px;
  margin-right: 1rem;
}

.input-position {
  width: 100%;
  position: relative;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  font-size: 1.125rem !important;
}

.case-library-custom-toggle-slider {
  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background-color: white !important;
  }

  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background-color: #2967de !important;
  }

  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
    fill: transparent !important;
  }

  .mdc-switch__track {
    height: 18px !important;
    width: 34.5px !important;
    border-radius: 15px !important;
    opacity: 1;
  }

  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
    background: #d0d0d0 !important;
    border: 2px solid #bcbcbc !important;
  }

  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: white !important;
    border: 2px solid #2967de !important;
  }

  .mdc-switch__handle {
    height: 12px !important;
    width: 12px !important;
    background-color: #2967de !important;
    margin-left: 2px !important;
  }

  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__shadow {
    background-color: #ffffff !important;
  }

  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
    fill: transparent !important;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.highlight {
  position: relative !important;
  z-index: 10003 !important;
  pointer-events: none !important;
}

.dragging,
.dragging * {
  cursor: move !important;
}
